import { Button } from '@finn/design-system/atoms/button';
import { ShoppingCartFilled } from '@finn/design-system/icons/shopping-cart-filled';
import { ModalKey, useOpenModal } from '@finn/ua-modals';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { useGetPreviousCart } from '@finn/ua-vehicle';
import { cn } from '@finn/ui-utils';
import React, { useCallback, useEffect } from 'react';

import { useGetPreviousDeal } from '~/modules/checkout/hooks/useGetPreviousDeal';

// TODO design-system: refactor !important styles after migration to new typography APP-1443

// thank you page shouldn't show cart button as user just finished the checkout
const hideCartOnPaths = ['thank_you'];

export const CartButton: React.FC = () => {
  const [shouldHide, setShouldHide] = React.useState(false);
  const openModal = useOpenModal();
  const { data } = useGetPreviousDeal();
  const previousCart = useGetPreviousCart();
  const track = useTrackingStore((state) => state.track);
  const previousDealId = data?.dealId;
  const hasCartOrDeal = previousCart?.isCart || previousDealId;
  const hasCartOrDealOrPDP =
    previousCart?.isCart || previousCart?.isPDP || previousDealId;

  const onButtonClick = useCallback(() => {
    if (hasCartOrDealOrPDP) {
      track(TrackingEventName.BASKET_CLICKED, {
        location: 'full-basket',
      });
      openModal(ModalKey.RETURN_TO_CHECKOUT);
    } else {
      track(TrackingEventName.BASKET_CLICKED, {
        location: 'full-basket',
      });
      openModal(ModalKey.EMPTY_CART);
    }
  }, [hasCartOrDealOrPDP, openModal]);

  useEffect(() => {
    const currentPath = window.location.pathname || '';
    const shouldHideOnCurrentPath = hideCartOnPaths.some((path) =>
      currentPath.includes(path)
    );
    setShouldHide(shouldHideOnCurrentPath);
  }, []);

  if (shouldHide) {
    return null;
  }

  return (
    <>
      <Button
        aria-label="Warenkorb"
        onClick={onButtonClick}
        variant="secondaryWhite"
        className="hover:border-pearl relative h-[53px] border border-solid border-transparent px-3"
      >
        <span
          className={cn({
            [cn(
              'visible absolute -right-1.5 -top-1.5 inline-block h-4 w-4 scale-100 rounded-[50%] transition-all duration-200 ease-in-out',
              'body-12-semibold bg-trustedBlue !right-1.5 !top-1.5 text-white before:inline after:inline'
            )]: hasCartOrDeal,
            'invisible inline-block w-0 scale-50': !hasCartOrDeal,
          })}
        >
          1
        </span>
        <ShoppingCartFilled />
      </Button>
    </>
  );
};
