import { Avatar } from '@finn/design-system/atoms/avatar';
import { PersonFilled } from '@finn/design-system/icons/person-filled';
import { ModalKey, useOpenModal } from '@finn/ua-modals';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { cn, IFinnSession, useSession } from '@finn/ui-utils';
import { useCallback, useMemo } from 'react';

const useInitialsAndPicture = (session: IFinnSession | null) => {
  const initials = useMemo(() => {
    if (!session?.user?.email) {
      return '';
    }

    const email = session?.user?.email || '';
    const firstname = session?.user?.hs_first_name;
    const lastname = session?.user?.hs_last_name;

    if (firstname && lastname) {
      return `${firstname[0]}${lastname[0]}`.toUpperCase();
    } else {
      return `${email[0]}${email[1]}`.toUpperCase();
    }
  }, [
    session?.user?.email,
    session?.user?.hs_first_name,
    session?.user?.hs_last_name,
  ]);

  // google one tap gives picture but normal google login gives image
  return { initials, picture: session?.user?.picture || session?.user?.image };
};

type ProfileButtonProps = {
  id?: string;
  onClick?: () => void;
};

export const ProfileButton = ({ id, onClick }: ProfileButtonProps) => {
  const [session] = useSession();
  const { initials, picture } = useInitialsAndPicture(session);
  const openModal = useOpenModal();
  const handleClick = useCallback(() => {
    if (!session) {
      interactionTrackingEvent(TrackingEventName.LOGIN_NAVBAR_CLICKED, {
        isEmptyBasket: false,
      });
      openModal(ModalKey.LOGIN);

      return;
    } else {
      interactionTrackingEvent(TrackingEventName.PORTAL_MENU_CLICKED, {});
    }
    onClick?.();
  }, [openModal, session, onClick]);

  const pictureOrInitials = picture ? (
    <img
      src={picture}
      alt={initials}
      className="h-8 w-8 rounded-full"
      referrerPolicy="no-referrer"
    />
  ) : (
    initials
  );

  return (
    <Avatar
      aria-label="Alle Abonnements"
      id={id}
      className={cn(
        'my-auto box-content cursor-pointer rounded-sm border-transparent bg-transparent p-2',
        'md:hover:bg-snow md:hover:border-pearl hover:bg-transparent hover:fill-black active:bg-transparent',
        {}
      )}
      data-testid="account-button"
      onClick={handleClick}
    >
      <div
        className={cn('outline-pearl flex items-center justify-center', {
          'outline outline-1': initials,
        })}
      >
        {pictureOrInitials || <PersonFilled className="min-h-6 min-w-6" />}
      </div>
    </Avatar>
  );
};
