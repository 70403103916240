import { Facebook } from '@finn/design-system/icons/facebook';
import { FilledYoutube } from '@finn/design-system/icons/filled-youtube';
import { SocialInstagram } from '@finn/design-system/icons/social-instagram';
import { SoicialLinkedin } from '@finn/design-system/icons/soicial-linkedin';
import { FooterSocialLink, SocialPlatforms } from '@finn/ui-cosmic';
import React from 'react';

type Props = {
  socialLink: FooterSocialLink;
};

function resolveIcon(platformName: string) {
  switch (platformName) {
    case SocialPlatforms.Facebook:
      return <Facebook />;
    case SocialPlatforms.Instagram:
      return <SocialInstagram />;
    case SocialPlatforms.YouTube:
      return <FilledYoutube />;
    case SocialPlatforms.LinkedIn:
      return <SoicialLinkedin />;
    default:
      return (platformName || '').slice(0, 2).toUpperCase();
  }
}

const SocialLink: React.FC<Props> = ({ socialLink }) => {
  return (
    <a
      href={socialLink.link_to_the_platform}
      target="_blank"
      className="fill-white"
      rel="noreferrer"
      aria-label={
        socialLink?.platform_name?.key || socialLink.link_to_the_platform
      }
    >
      {resolveIcon(socialLink?.platform_name.key)}
    </a>
  );
};
export default SocialLink;
